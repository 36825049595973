.download-button {
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px 0px 30px;
    color: #404A3D;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;

    &.white {
        background-color: #fff;
        border-radius: 40px;

        .download-container {
            height: 62px;
            width: 62px;
            background-color: rgba(91, 140, 81, 1);
            color: #fff;
            border-radius: 31px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }

    &.yellow {
        background-color: #EDDD5E;
        border-radius: 40px;

        .download-container {
            height: 62px;
            width: 62px;
            color: rgba(91, 140, 81, 1);
            background-color: #fff;
            border-radius: 31px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}