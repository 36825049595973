
.statistics-section {
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;

    .section-header {
        min-height: 46px;
        border-radius: 20px;
        background: rgba(91, 140, 81, 1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px 10px 20px;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);

        @media (max-width: 767px) {
            height: auto;
            font-size: 16px;
        }
        cursor: pointer;

        .dropdown-title {
            font-size: 28px;
            font-weight: 500;
            line-height: 30px;
            color: rgba(255, 255, 255, 1);

            @media (max-width: 767px) {
                font-size: 20px;
            }
        }

        svg {
            min-width: 30px;
            transition: transform 0.3s ease; 
        }

        .arrow-icon.open {
            transform: rotate(180deg); 
        }
    }

    .dropdown-section-content {
        max-height: 0;
        transition: max-height 0.5s ease, opacity 0.5s ease;
        opacity: 0;

        &.open {
        margin-top: 20px;

            max-height: 100%; 
            opacity: 1;
        }
    }
}
