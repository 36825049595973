.event-1-page {
    display: flex;
    justify-content: center;
    gap: 30px;

    .page-content {
        width: 800px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 767px) {
            width: auto;
        }

        .topic-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .title {
                color: #404A3D;
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                line-height: 46px;
                margin-bottom: 40px;

                @media (max-width: 767px) {
                    font-size: 32px;
                }
            }

            .section-content {
                display: flex;
                flex-direction: column;

                color: #666;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 25.6px;
                gap: 20px;

                @media (max-width: 767px) {
                    font-size: 16px;
                }


                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
            }

            .dropdown-title {
                padding: 0px 30px;
                display: flex;
                align-items: center;
                height: 65px;
                border-radius: 20px;
                background: #5B8C51;
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 46px;
            }

            .subtitle-content {
                .subtitle {
                    color: #666;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 25.6px;

                }

                ul {
                    margin: 0px;
                    padding-left: 0px;
                }

                li {
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    svg {
                        min-width: 20px;
                        min-height: 20px;
                    }

                    p {
                        color: #666;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25.6px;

                        @media (max-width: 767px) {
                            font-size: 16px;
                        }
                    }
                }
            }

            .workshops-container {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                @media (max-width: 767px) {
                    flex-direction: column;
                }

                .workshop {
                    margin-top: 30px;
                    flex: 0 1 calc(50% - 10px);
                    box-sizing: border-box;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 10px;

                    .number-indicator {
                        margin: 0px;
                    }

                    .separator {
                        width: 100%;
                        height: 1px;
                        background-color: rgba(64, 74, 61, 0.2)
                    }

                    .title {
                        margin: 0px;
                        color: rgba(131, 195, 65, 1);
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 32px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;

                        span {
                            color: rgba(64, 74, 61, 1);
                        }
                    }

                    .description {
                        width: 80%;
                        color: rgba(102, 102, 102, 1);
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 25.6px;
                        @media (max-width: 767px) {
                            width: 100%;
                            font-size: 16px;
                        }
                    }

                    .link {
                        display: flex;
                        gap: 10px;
                        font-size: 18px;
                        align-items: center;
                        color: rgba(131, 195, 65, 1);
                        line-height: 24px;
                        text-align: left;
                        cursor: pointer;

                        @media (max-width: 767px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

    }
}