.image-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.image-modal-content {
    position: relative;
    padding: 10px;
    border-radius: 8px;

    img {
        display: block;
        max-height: 90vh;
        max-width: 100%;
    }

    .close-button {
        position: absolute;
        top: 5px;
        right: 5px;
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: black;
    }
}