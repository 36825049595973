.research-page {
    display: flex;
    justify-content: center;
    gap: 30px;

    .page-content {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 767px) {
            width: auto;
            gap: 30px;
        }

        p {
            margin: 0px;
        }

        .title {
            color: #404A3D;
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 46px;

            @media (max-width: 767px) {
                font-size: 32px;
            }
        }

        .research-topic-container {
            display: flex;
            flex-direction: row;
            gap: 100px;

            @media (max-width: 767px) {
                flex-direction: column;
                gap: 30px;
            }

            .subtopic-container {
                display: flex;
                flex-direction: column;
                gap: 30px;



                .description {
                    color: rgba(102, 102, 102, 1);
                    font-size: 20px;

                    @media (max-width: 767px) {
                        font-size: 16px;
                    }

                    &.bolded {
                        font-size: 20px;
                        font-weight: 600;

                        @media (max-width: 767px) {
                            font-size: 16px;
                        }
                    }
                }



                .research-section {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    @media (max-width: 767px) {
                        margin-top: 10px;
                    }

                    .research-item {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        svg {
                            min-width: 20px;
                            height: 20px;
                        }

                        span {
                            line-height: 26px;
                            color: rgba(64, 74, 61, 1);
                            font-weight: 500;
                            font-size: 20px;

                            @media (max-width: 767px) {
                                font-size: 16px;
                            }
                        }
                    }
                }

                .infrastructure-section {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .infrastructure-item {
                        display: flex;
                        align-items: center;
                        gap: 10px;


                        span {
                            line-height: 26px;
                            color: rgba(64, 74, 61, 1);
                            font-weight: 400;

                            font-size: 20px;

                            @media (max-width: 767px) {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        .reports-and-results-container {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .reports-and-results {
                display: flex;
                gap: 40px;

                @media (max-width: 767px) {
                    flex-direction: column;
                }

                .subtitle {
                    height: 46px;
                    border-radius: 20px;
                    background: rgba(91, 140, 81, 1);
                    display: flex;
                    align-items: center;
                    padding: 10px 40px 15px 20px;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 30px;
                    color: rgba(255, 255, 255, 1);

                    @media (max-width: 767px) {
                        height: auto;
                        font-size: 16px;
                    }
                }

                .subtopic {
                    // width: 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 45px;

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    .category {
                        display: flex;
                        flex-direction: column;

                        .title {
                            font-size: 22px;
                            color: rgba(102, 102, 102, 1);
                            font-weight: 600;


                            @media (max-width: 767px) {
                                font-size: 16px;
                            }
                        }

                        .results {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 20px;
                            font-size: 20px;

                            @media (max-width: 767px) {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }


        .performances-container {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .performances-title {
                color: rgba(64, 74, 61, 1);
                font-weight: 500;
                font-size: 30px;

                @media (max-width: 767px) {
                    font-size: 26px;
                }
            }

            .sections-container {
                display: flex;
                width: 100%;
                justify-content: space-between;

                @media (max-width: 767px) {
                    flex-direction: column;
                    gap: 40px;
                }
            }

            .performances-section {
                display: flex;
                flex-direction: column;
                gap: 10px;

                &.section-2 {
                    width: 45%;

                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }

                .item {
                    color: rgba(102, 102, 102, 1);
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 20px;

                    @media (max-width: 767px) {
                        font-size: 16px;
                    }

                    .dot {
                        min-width: 5px;
                        min-height: 5px;
                        border-radius: 50%;
                        background-color: rgba(64, 74, 61, 1);
                    }
                }
            }
        }



            .subtitle {
                height: 46px;
                border-radius: 20px;
                background: rgba(91, 140, 81, 1);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 40px 10px 20px;
                font-size: 22px;
                font-weight: 500;
                line-height: 30px;
                color: rgba(255, 255, 255, 1);

                @media (max-width: 767px) {
                    height: auto;
                    font-size: 16px;
                }
            }

            .resources-panel-container {
                display: flex;
                gap: 80px;

                @media (max-width: 767px) {
                    flex-direction: column;
                }

                .panel {
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    .title {
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 32px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;

                        @media (max-width: 767px) {
                            font-size: 22px;
                        }

                    }

                    .section {
                        margin-top: 10px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        font-size: 18px;

                        @media (max-width: 767px) {
                            font-size: 16px;
                        }
                    }
                }

                .separator {
                    height: 5px;
                    background-color: rgba(217, 217, 199, 1)
                }
            }
    }


    .separator {
        height: 1px;
        width: 100%;
        background-color: rgba(217, 217, 199, 1)
    }

    .hidden {
        visibility: hidden;

        @media (max-width: 767px) {
            display: none;
        }
    }

    .dot {
        min-width: 5px;
        min-height: 5px;
        border-radius: 50%;
        background-color: rgba(64, 74, 61, 1);
        ;
    }


    .download {
        color: #5B8C51 !important;
        cursor: pointer;
        text-decoration: underline;
        transition: color 0.3s ease, transform 0.2s ease;

        &:hover {
            color: #4A7342;
            text-decoration: none;
        }

        &:active {
            color: #3B5C36;
            transform: scale(0.95);
        }
    }
}