.switch-language-mobile {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px;

    .language-button {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 10px 16px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 5px;
        border: 1px solid #ccc;
        cursor: pointer;
        background: rgba(237, 221, 94, 0.5);
        transition: all 0.2s ease-in-out;

        &:hover {
            background: rgba(237, 221, 94, 0.8);
        }

        &.active {
            background: #EDDD5E;
            border: 2px solid #b39b3e;
        }

        .language-flag {
            width: 20px;
            height: 14px;
            border-radius: 2px;
            object-fit: cover;
        }
    }
}
