.workshop-layout {
    display: flex;
    justify-content: center;
    gap: 30px;

    .page-content {
        width: 800px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 767px) {
            width: auto;
        }

        .topic-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            p {
                margin: 0px;
            }

            .normal-text {
                color: #666;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 25.6px;

                @media (max-width: 767px) {
                    font-size: 16px;
                }

            }

            .title {
                color: #404A3D;
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                line-height: 46px;
                margin-bottom: 40px;

                @media (max-width: 767px) {
                    font-size: 32px;
                }
            }

            .section-content {
                display: flex;
                flex-direction: column;

                color: #666;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 25.6px;
                gap: 20px;

                @media (max-width: 767px) {
                    font-size: 16px;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
            }

            .dropdown-title {
                padding: 0px 30px;
                display: flex;
                align-items: center;
                height: 65px;
                border-radius: 20px;
                background: #5B8C51;
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 46px;
            }

            .subtitle-content {
                .subtitle {
                    color: #666;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 25.6px;

                }
                ul {
                    margin: 0px;
                }

                li {

                    svg {
                        min-width: 20px;
                        min-height: 20px;
                    }

                    p {
                        color: #666;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25.6px;

                        @media (max-width: 767px) {
                            font-size: 16px;
                        }
                    }
                }
            }

            
        }
        .workshop-gallery-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            img {
                width: 100%;
                max-height: 500px;
                object-fit: contain;
            }
        }
    }
}