.archive-page {
    display: flex; 
    justify-content: center;
    .page-content {
        width: 65%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 767px) {
           width: auto;

           img {
            width: 100%;
            height: 100%;
           }
        }

        p {
            margin: 0px;
        }


        .topic-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .title {
                color: #404A3D;
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                line-height: 46px;

                @media (max-width: 767px) {
                    font-size: 30px;
                }
            }

            .section-content {
                display: flex;
                flex-direction: column;

                color: #666;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 25.6px;
                gap: 5px;

                @media (max-width: 767px) {
                    font-size: 16px;
                }


                ul { 
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
            }

            .dropdown-title {
                padding: 0px 30px;
                display: flex;
                align-items: center;
                min-height: 65px;
                border-radius: 20px;
                background: #5B8C51;
                color: #FFF;
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: 46px;

                @media (max-width: 767px) {
                    height: auto;
                    font-size: 24px;
                  }
                  
            }

            .subtitle-content {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                .subtitle {
                    color: #666;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25.6px;
                    
                    @media (max-width: 767px) {
                        font-size: 16px;
                    }
                }

                ul {
                    margin: 0px;
                }

                li {
                    p {
                        color: #666;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25.6px;

                        @media (max-width: 767px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .download {
        display: flex;
        gap: 10px;
        justify-content: start;
        align-items: center;
        // color: #5B8C51;
        cursor: pointer;
        // text-decoration: underline;
        transition: color 0.3s ease, transform 0.2s ease;
    
        &:hover {
            color: #4A7342; 
            text-decoration: none; 
        }
    
        &:active {
            color: #3B5C36; 
            transform: scale(0.95); 
        }
    }

    .flex-row {
        display: flex;
        flex-direction: row;

        @media (max-width: 767px) {
            flex-wrap: wrap;
        }
    }

    
    .space-between {
        justify-content: space-between;
    }

    .justify-center {
        justify-content: center;
    }

    .align-items {
        align-items: center;
    }

    .m-bt-5 {
        margin-bottom: 5px;
    }

    .m-bt-10 {
        margin-bottom: 10px;
    }

    .flex-column {
        display: flex;
        flex-direction: column;
    }

    .gap-10 {
        gap: 10px;
    }

    .gap-20 {
        gap: 20px;
    }

    .gap-30 {
        gap: 30px;
    }

    .gap-40 {
        gap: 40px;
    }

    .flex-wrap {
        display: flex;
        flex-wrap: wrap;
    }

    .width-30 {
        width: 30%;
    }

    .width-40 {
        width: 40%;
    }

    .width-50 {
        width: 50%;
    }

    .flex-1 {
        flex: 1;
    }

    .year-subtitle {
        font-size: 20px;
        font-weight: 700;
        color: #666;
    }


    .flex-align-space-between-center {
        justify-content: space-between;
        align-items: center;
    }

    .flex-align-space-between-start {
        justify-content: space-between;
        align-items: start;
    }


    .announcements-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;

        @media (max-width: 767px) {
            display: flex;
            flex-wrap: wrap;
        }
    }
}