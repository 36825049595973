.development-page {
    display: flex;
    justify-content: center;
    gap: 30px;

    .side-menu-container {
        @media (max-width: 767px) {
            order: 2;
        }
    }

    .page-content {
        width: 900px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 767px) {
            width: auto;
        }

        p {
            margin: 0px;
        }


        .topic-container {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .title {
                color: #404A3D;
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                line-height: 46px;

                @media (max-width: 767px) {
                    font-size: 32px;
                }
            }

            .section-content {
                display: flex;
                flex-direction: column;

                color: #666;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 25.6px;
                gap: 20px;

                @media (max-width: 767px) {
                    font-size: 16px;
                }


                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
            }

            .dropdown-title {
                padding: 0px 30px;
                display: flex;
                align-items: center;
                height: 65px;
                border-radius: 20px;
                background: #5B8C51;
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 46px;
            }

            .subtitle-content {
                .subtitle {
                    color: #666;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25.6px;

                }

                ul {
                    margin: 0px;
                }

                li {
                    p {
                        color: #666;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25.6px;
                    }
                }
            }
        }

        .bases-container {
            display: flex;
            justify-content: center;
            gap: 20px;

            @media (max-width: 767px) {
                flex-direction: column;
                align-items: center;
            }

            .item {
                width: 50%;
                position: relative;
                padding: 20px;
                border-radius: 26px;
                background: #FFF;
                display: flex;
                flex-direction: column;
                gap: 15px;
                box-sizing: border-box;
                transition: transform 0.3s ease, box-shadow 0.3s ease;
                cursor: pointer;
            
                opacity: 0;
                transform: translateY(30px);
                animation: fadeInUp 0.6s ease-out forwards;
            
                &:hover {
                    transform: scale(1.03);
                    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
                }
            
                @media (max-width: 767px) {
                    width: 100%;
                    gap: 5px;
                }
            
                .image-container {
                    box-sizing: border-box;
            
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 26px;
                        transition: transform 0.3s ease;
            
                        .item:hover & {
                            transform: scale(1.05);
                        }
                    }
                }
            
                .category {
                    color: #999;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                }
            
                .title {
                    height: 80px;
                    color: #404A3D;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                    text-transform: capitalize;

                    @media (max-width: 767px) {
                        font-size: 22px;
                    }
                }
            
                .separator {
                    height: 1px;
                    width: 100%;
                    background-color: rgba(64, 74, 61, 0.10);
                }
            
                .description {
                    width: 80%;
                    color: rgba(64, 74, 61, 0.80);
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25.6px;
            
                    @media (max-width: 767px) {
                        font-size: 16px;
                        height: auto;
                    }
                }
            
                .arrow-container {
                    bottom: 0;
                    right: 0;
                    position: absolute;
                    display: flex;
                    width: 55px;
                    height: 55px;
                    border-radius: 50px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    background-color: #EDDD5E;
                    transition: transform 0.3s ease;
                }
            }
            
            @keyframes fadeInUp {
                0% {
                    opacity: 0;
                    transform: translateY(80px);
                }
                100% {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            

        }
    }
}