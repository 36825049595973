.announcements-page {
    display: flex;
    justify-content: center;
    gap: 30px;

    .page-content {
        width: 900px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 767px) {
            width: 100%;
        }

        .topic-container {
            .title {
                color: #404A3D;
                font-size: 36px;
                font-weight: 500;
                margin-bottom: 20px;
                cursor: pointer;

                @media (max-width: 767px) {
                    font-size: 24px;
                }
            }

            .announcements-table {
                display: flex;
                flex-direction: column;
                gap: 15px;

                @media (min-width: 768px) {
                    display: table;
                    width: 100%;
                    border-collapse: collapse;

                    th,
                    td {
                        padding: 15px;
                        text-align: left;
                        border-bottom: 1px solid #ddd;
                    }

                    th {
                        font-weight: 600;
                    }

                    tr:hover {
                        background-color: #f9f9f9;
                    }

                    a {
                        display: flex;
                        align-items: center;

                        svg {
                            width: 24px;
                            height: 24px;
                            fill: #ed4c4c;
                            transition: transform 0.3s ease;

                            &:hover {
                                transform: scale(1.2);
                            }
                        }
                    }
                }

                .announcement-row {
                    background: #fffdea;
                    border-bottom: 2px solid #dda;
                    padding: 15px;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                
                    .row-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 600;
                        cursor: pointer;
                
                        .toggle-icon {
                            font-size: 14px;
                            font-weight: bold;
                            transition: transform 0.3s ease;
                        }
                    }
                
                    .row-details {
                        max-height: 0;
                        opacity: 0;
                        visibility: hidden;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        transition: max-height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
                
                        div {
                            display: flex;
                            justify-content: space-between;
                            gap: 40px;
                
                            strong {
                                font-weight: 600;
                            }
                
                            span {
                                color: #666;
                            }
                
                            a {
                                svg {
                                    fill: #ed4c4c;
                                }
                            }
                        }
                    }
                
                    &.expanded {
                        .row-header {
                            border-bottom: 1px solid #ddd;
                            margin-bottom: 10px;
                
                            .toggle-icon {
                                transform: rotate(180deg); 
                            }
                        }
                
                        .row-details {
                            max-height: 500px; 
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
                
            }
        }
    }
}
