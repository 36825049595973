.app-layout {
    display: flex;
    flex-direction: column;
    background-color: #FFFDEA;


    @media (max-width: 1300px) {
        padding: 40px 20px;
      }

      .page {
        padding: 40px 0px;

        @media (max-width: 767px) {
          flex-direction: column;
        }
        
      }
}