.home-page-news-container {
    border-radius: 30px;
    background: #EDDD5E;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 30px;


    @media (max-width: 767px) {
        box-sizing: border-box;
        padding: 20px 20px;
        width: 100%;
    }

    .title {
        color: #404A3D;
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
    }

    .help {
        width: 80px;
        min-height: 30px;
        background-color: white;
        padding: 0px 20px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .item {
        width: 90%;
        display: flex;
        gap: 30px;

        .no-image {
            min-width: 300px;
            height: 220px;
            background-color: rgb(223, 223, 223);
            @media (max-width: 767px) {
                width: auto;
                min-width: 120px;
                height: 90px;
            }
        }

        img {
            border-radius: 20px;
            width: 300px;
            height: 220px;
            @media (max-width: 767px) {
                width: 120px;
                height: 90px;
            }
        }

        .content {
            color: #404A3D;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;

            @media (max-width: 767px) {
                color: #404A3D;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }

      


        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .separator {
        height: 1px;
        width: 90%;
        background-color: rgba(91, 140, 81, 0.4)
    }
}