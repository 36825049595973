.app-mobile-header {
    display: flex;
    flex-direction: column;

    .logo-menu-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .search-burger-menu-container {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: end;
            gap: 40px;

            .search {
                border-radius: 24px;
                background-color: rgba(237, 221, 94, 1);
                display: flex;
                width: 20px;
                height: 20px;
                padding: 15px 16px 17px 16px;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
            }
        }

        .burger-menu {
            cursor: pointer;
        }
    }

    .mobile-navigation {
        position: fixed;
        top: 0;
        left: 100%;
        width: 80%;
        height: 100%;
        background-color: #FFFDEA;
        box-shadow: -4px 0 6px rgba(0, 0, 0, 0.1);
        z-index: 100;
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 20px;
        transform: translateX(0);
        transition: transform 0.3s ease;

        overflow-y: auto;
        max-height: 100vh; 

        &.open {
            transform: translateX(-100%);
        }

        .close-button {
            align-self: flex-end;
            font-size: 24px;
            font-weight: bold;
            cursor: pointer;
        }

        .nav-item {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .main-item {
                font-size: 18px;
                font-weight: 600;
                color: #404A3D;
                cursor: pointer;
                padding: 10px 0;
                border-bottom: 1px solid #ddd;

                &:hover {
                    color: #eddd5e;
                }
            }

            .subpaths-container {
                display: flex;
                flex-direction: column;
                padding-left: 20px;
                gap: 15px;

                .subpath-item {
                    font-size: 18px;
                    color: #404A3D;
                    cursor: pointer;
                    text-wrap: wrap;

                    &:hover {
                        color: #eddd5e;
                    }
                }
            }

            .switch-language-container {
                margin-top: auto;
                padding-bottom: 20px;
            }
        }
    }
}
