.laboratory-2-personal {
    padding: 20px;
    text-align: center;

    .section-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
        color: #2c3e50;
    }

    .team-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }

    .team-member {
        border-radius: 10px;
        padding: 15px;
        max-width: 300px;
        text-align: center;

        .team-photo {
            width: 150px;
            height: 150px;
            object-fit: cover;
            border-radius: 50%;
            margin-bottom: 10px;
        }

        .team-name {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        .team-role {
            font-size: 22px;
            color: #666;
            margin-bottom: 5px;
        }

        .team-id {
            font-size: 20px;
            color: #999;
            margin-bottom: 5px;
        }

        .team-description {
            font-size: 20px;
            color: #333;
        }
    }
}

@media (max-width: 768px) {
    .team-container {
        flex-direction: column;
        align-items: center;
    }

    .team-member {
        width: 90%;
    }
}
