.laboratory-1-page {
    display: flex;
    justify-content: center;
    gap: 30px;

    .page-content {
        width: 900px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: 767px) {
           width: auto;
        }

        p {
            margin: 0px;
        }




        .topic-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .title {
                color: #404A3D;
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                line-height: 46px;

                @media (max-width: 767px) {
                    font-size: 32px;
                } 
            }

            .subtitle {
                color: #404A3D;
                font-size: 25px;
                font-style: normal;
                font-weight: 500;
                line-height: 46px;

                @media (max-width: 767px) {
                    font-size: 22px;
                } 
            }

            .normal-text {
                color: #666;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 25.6px;

                @media (max-width: 767px) {
                    font-size: 16px;
                }
            }

            .section-content {
                display: flex;
                flex-direction: column;

                color: #666;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                gap: 5px;

                @media (max-width: 767px) {
                    font-size: 16px;
                }


                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }

            .dropdown-title {
                padding: 0px 30px;
                display: flex;
                align-items: center;
                height: 65px;
                border-radius: 20px;
                background: #5B8C51;
                color: #FFF;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 46px;
            }

            .subtitle-content {
                .subtitle {
                    color: #666;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 25.6px;
                    
                    @media (max-width: 767px) {
                        font-size: 16px;
                    }
                }

                ul {
                    margin: 0px;
                }

                li {
                    p {
                        color: #666;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25.6px;

                        @media (max-width: 767px) {
                            font-size: 16px;
                        }
                    }
                }
            }


            .submenu-container {
                margin: 10px 0;
                display: flex;
                align-items: center;
                gap: 30px;
      
            
                animation: fadeIn 0.6s ease-in-out;

                @media (max-width: 767px) {
                    margin-top: 15px;
                    padding-bottom: 15px;
                    padding-left: 10px;
                    width: auto;
                    overflow-x: scroll;
                 }
            
                .submenu-item,
                .submenu-button {
                    box-sizing: border-box;
                    padding: 3px 15px;
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 50px;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    color: rgba(51, 51, 51, 1);
                    background-color: transparent;
                    border: none; 
                    outline: none; 
                    font-family: inherit;
                    font-size: 20px;

                    @media (max-width: 767px) {
                        padding: 5px 15px;
                        height: auto;
                        font-size: 16px;
                     }
            
                    &:hover {
                        background-color: rgba(131, 195, 65, 0.8);
                        color: #fff;
                        transform: scale(1.05);
                        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
                    }
            
                    &.active {
                        background-color: rgba(131, 195, 65, 1);
                        color: #fff;
                        transform: scale(1.1);
                        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
                    }
                }
            }
            
            @keyframes fadeIn {
                from {
                    opacity: 0;
                    transform: translateY(-10px);
                }
                to {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
            
            
        }
    }
}