.app-footer {
    width: 100%;


    .categories-contact-info-container-section {
        position: relative;
        height: 140px;
        @media (max-width: 767px) {
            height: 400px;
        }
        
        .categories-contact-info-container {
            padding: 20px 0;
            position: absolute;
            left: -20px;
            width: 100vw;
            // height: 140px;
            background: #EDDD5E;
            display: flex;
            flex-direction: column;
            gap: 10px;
            


            @media (max-width: 1300px) {
                padding-top: 20px;
                flex-direction: column;
                height: auto;
                padding-bottom: 30px;
                gap: 20px;
                flex-wrap: wrap;
            }

            .flex-row {
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 100%;
                
            @media (max-width: 1300px) {
                padding-top: 20px;
                flex-direction: column;
                height: auto;
                padding-bottom: 30px;
                gap: 20px;
                flex-wrap: wrap;
            }
                .categories {
                    display: flex;
                    gap: 20px;
    
                    @media (max-width: 767px) {
                        gap: 5px;
                        flex-wrap: wrap;
                        justify-content: center;
                    }
    
                    .category {
                        display: flex;
                        align-items: center;
                        color: #404A3D;
                        font-size: 16px;
                        font-style: normal;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
    
                        @media (max-width: 767px) {
                            font-size: 13px;
                        }
    
                        .dot {
                            margin-left: 20px;
                            width: 6px;
                            height: 6px;
                            border-radius: 3px;
                            background: #FFF;
    
                            @media (max-width: 767px) {
                                margin-left: 5px;
                            }
                        }
                    }
    
    
                }
    
                .contact-info {
                    display: flex;
                    gap: 20px;
                    color: #404A3D;
                    font-size: 19px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
    
    
                    @media (max-width: 767px) {
                        flex-direction: column;
                        font-size: 15px;
                    }
    
                    .phone {
                        display: flex;
                        align-items: center;
                        height: 50px;
                        gap: 15px;
    
    
                        .phone-icon {
                            height: 100%;
                            width: 50px;
                            background-color: #fff;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
    
                    .email {
                        display: flex;
                        align-items: center;
                        height: 50px;
                        gap: 15px;
    
                        .email-icon {
                            height: 100%;
                            width: 50px;
                            background-color: #fff;
                            border-radius: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }

            .social-icons {
                width: 100%;
                display: flex;
                justify-content: center;
                gap: 12px;
              
                a {
                  svg {
                    width: 50px;
                    height: 50px;
                    fill: #fff; 
                    transition: transform 0.2s ease;
              
                    &:hover {
                      transform: scale(1.1);
                    }
                  }
                }
              }
            
        }
    }

    .details-container {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media (max-width: 767px) {
            flex-direction: column;
        }

        .logo-info {
            width: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;

            @media (max-width: 767px) {
                width: auto;
                align-items: center;
            }

            .info {
                color: #404A3D;
                font-size: 20px;
                font-style: normal;
                text-align: center;
                font-weight: 400;
                line-height: 26px;

                @media (max-width: 767px) {
                    font-size: 16px;
                }
            }
        }

        .content {
            width: 50%;
            display: flex;
            flex-direction: column;
            padding: 40px 20px;
            color: #333;

            @media (min-width: 768px) and (max-width: 1500px) {
                width: 85%;
            }

            @media (max-width: 767px) {
                width: auto;
                align-items: center;
                padding: 0px;
            }

            .description-links-container {
                width: 85%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin-bottom: 20px;

                @media (max-width: 767px) {
                    width: 100%;
                    align-items: center;
                }

                .description {
                    text-align: start;
                    color: #404A3D;
                    font-size: 29px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 42px;

                    @media (max-width: 767px) {
                        width: 100%;
                        color: #404A3D;
                        text-align: center;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 36px;
                    }
                }

                .footer-columns {
                    display: flex;
                    align-items: start;
                    width: 100%;
                    gap: 20px;

                    @media (max-width: 1300px) {
                        flex-direction: column;
                    }

                    .column {
                        flex: 1;
                        text-align: left;

                        &.address {

                            @media (max-width: 767px) {}

                        }

                        h4 {
                            font-size: 20px;
                            font-weight: bold;
                            margin-bottom: 10px;

                            @media (max-width: 767px) {
                                font-size: 16px;
                            }
                        }

                        ul {
                            list-style: none;
                            padding: 0;
                            margin: 0;

                            li {
                                white-space: nowrap;
                                font-size: 18px;
                                margin-bottom: 5px;

                                @media (max-width: 767px) {
                                    font-size: 14px
                                }
                            }
                        }

                        p {
                            font-size: 18px;

                            @media (max-width: 767px) {
                                font-size: 14px
                            }
                        }
                    }
                }
            }

            .separator {
                height: 0.6px;
                width: 100%;
                background-color: rgba(64, 74, 61, 0.20);
                ;
            }

            .footer-bottom {
                display: flex;
                justify-content: center;
                color: #666;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 22.4px;

                @media (max-width: 767px) {
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 20px;
                    font-size: 14px;
                }

            }
        }
    }
}