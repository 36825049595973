.info-request-public-interest-container {
    box-sizing: border-box;
    border-radius: 30px;
    background: #fff;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .title-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-right: 10px;

        .title {
            color: #404A3D;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            text-transform: capitalize;

            @media (max-width: 767px) {
                font-size: 24px;
            }
        }
    }

    .content {
        padding: 30px 45px;
        padding-right: 30px;
        color: #666;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 25.6px; 
        display: flex;
        flex-direction: column;
        gap: 30px;

        @media (max-width: 767px) {
            padding: 30px 0px;
            font-size: 16px;
        }

        .item {
            display: flex;
            flex-direction: column;
            gap: 5px;
            .bolded {
                font-weight: 700;
            }


            &.row {
                flex-direction: row;

                .download {
                    color: #5B8C51;
                    cursor: pointer;
                    text-decoration: underline;
                    transition: color 0.3s ease, transform 0.2s ease;
                
                    &:hover {
                        color: #4A7342; 
                        text-decoration: none; 
                    }
                
                    &:active {
                        color: #3B5C36; 
                        transform: scale(0.95); 
                    }
                }
            }

            .download {
                color: #5B8C51 !important;
                cursor: pointer;
                text-decoration: underline;
                transition: color 0.3s ease, transform 0.2s ease;

                a {
                    text-decoration: none;
                    color: inherit;
                }
            
                &:hover {
                    color: #4A7342; 
                    text-decoration: none; 
                }
            
                &:active {
                    color: #3B5C36; 
                    transform: scale(0.95); 
                }
            }
        }

       
    }
    
   
}