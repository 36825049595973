body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.hide-md {

  @media (max-width: 767px) {
    display: none !important;

  }
}

.navigate {
  color: #5B8C51 !important;
  cursor: pointer;
  text-decoration: none;

  transition: color 0.3s ease, transform 0.2s ease;

  &:hover {
    color: #4A7342;
    text-decoration: underline;

  }

  &:active {
    color: #3B5C36;
    transform: scale(0.95);
  }
}


.flex-row {
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
      flex-wrap: wrap;
  }
}


.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.m-bt-5 {
  margin-bottom: 5px;
}

.m-bt-10 {
  margin-bottom: 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.width-30 {
  width: 30%;
}

.width-40 {
  width: 40%;
}

.width-50 {
  width: 50%;
}

.flex-1 {
  flex: 1;
}

.year-subtitle {
  font-size: 20px;
  font-weight: 700;
  color: #666;
}


.flex-align-space-between-center {
  justify-content: space-between;
  align-items: center;
}


   
