.carousel-wrapper {
    width: 80%;

    @media (max-width: 767px) {
        width: 100%;
    }

    .carousel-container {
        display: flex;
        flex-direction: column;
        z-index: 1;
        gap: 15px;


        .title-help {
            display: inline-flex;
            align-items: center;
            gap: 15px;
            padding: 5px 20px;
            border-radius: 30px;
            background-color: white;
            white-space: nowrap; 
            width: fit-content;

            span {
                display: block;
                color: #404A3D;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0.5px;
                text-transform: capitalize;
            }
        }

        .title-buttons {
            width: 100%;
            display: flex;

            .title {
                width: 50%;
                color: #FFF;
                font-size: 40px;
                font-style: normal;
                font-weight: 500;
                line-height: 60px;


                &.dark {
                    color: #FFF;
                }

                &.light {
                    color: #404A3D;
                }
            }

            .buttons-container {
                display: flex;
                width: 50%;
                justify-content: flex-end;
                gap: 20px;

                .button {
                    display: flex;
                    width: 60px;
                    height: 50px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: transform 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease;



                    &.dark {
                        border: 1px solid #FFF;
                        color: #FFF;

                        svg {
                            fill: #FFF;
                            color: #FFF;
                        }
                    }

                    &.light {
                        border: 1px solid #404A3D;
                        color: #404A3D;
                    }

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.1);
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
                        transform: scale(1.05);
                    }

                    &:active {
                        transform: scale(0.95);
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
                    }

                    .right {
                        transform: rotate(180deg);
                    }
                }
            }
        }


        .items {
            display: flex;
            gap: 20px;
            transition: opacity 0.5s ease;

            @media (max-width: 767px) {
                overflow-x: scroll;
                gap: 100px;
            }

            &.fade-in {
                opacity: 1;
            }

            &.fade-out {
                opacity: 0.5;
            }

            .carousel-item-wrapper {
                width: 33%;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }


        }
    }
}