.homepage-banner {
    position: relative;
    width: 80%;
    height: 800px;
    border-radius: 30px;
    overflow: hidden;

    @media (max-width: 767px) {
        width: 100%;
        height: auto;
        min-height: 600px;
    }

    .background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: inherit;
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: inherit;
        z-index: 2;

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80%;
            max-height: 80%;
            overflow-y: auto;
            padding: 20px;

            @media (max-width: 767px) {
                width: 95%;
                padding: 10px;
            }

            .icon-title-container {
                display: flex;
                align-items: center;
                gap: 15px;
                text-align: center;

                svg {
                    min-width: 80px;
                    height: 80px;

                    @media (max-width: 767px) {
                        width: 70px;
                        height: 70px;
                    }
                }

                .title {
                    font-size: 45px;
                    font-weight: 500;

                    @media (max-width: 767px) {
                        font-size: 32px;
                    }
                }
            }

            .partners-list {
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 20px;
                max-height: 400px;

                @media (max-width: 767px) {
                    max-height: 300px;
                    width: 100%;
                }

                .partner-category {
                    min-width: 200px;

                    h3 {
                        font-size: 22px;
                        font-weight: bold;
                        margin-bottom: 8px;
                        text-align: center;

                        @media (max-width: 767px) {
                            font-size: 18px;
                        }
                    }

                    ul {
                        list-style: none;
                        padding: 0;
                        text-align: center;

                        li {
                            margin-bottom: 5px;

                            a {
                                color: white;
                                text-decoration: none;
                                font-size: 18px;

                                @media (max-width: 767px) {
                                    font-size: 16px;
                                }

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
