.switch-language {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    .selected-language {
        display: flex;
        align-items: center;
        gap: 8px;
        gap: 5px;
        border-radius: 30px;
        background-color: rgba(237, 221, 94, 0.7);
        padding: 8px 12px;
        border-radius: 5px;
        border: 1px solid #ccc;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
    }

    .language-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        background: #EDDD5E;
        border: 1px solid #ccc;
        border-radius: 5px;
        list-style: none;
        margin: 5px 0;
        padding: 0;
        // width: 120px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        li {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 12px;
            cursor: pointer;
            transition: background 0.2s;

            &:hover {
                background: #f1c40f;
            }
        }
    }

    .language-flag {
        width: 20px;
        height: 14px;
        border-radius: 2px;
        object-fit: cover;
    }
}