.navigation-button {
        max-width: 120px;
        margin-top: 15px;
        padding: 12px 25px;
        background: rgba(91, 140, 81, 1);
        color: #fff;
        border: none;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #6aa92e;
        }
  
     
}